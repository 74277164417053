<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-tabs color="#002441">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>

        <!-- Recent Orders -->
        <v-tab-item>
          <RecentOrders />
        </v-tab-item>

        <!-- All Orders -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-row>
                <v-col>
                  <template>
                    <v-card>
                      <!-- <v-row class="mt-5">
                       
                        <v-col cols="4">
                            <v-card-title class="pb-10 ">
                           
                                <v-btn @click="exportTableToCSV('all_
                                .csv')" text>
                                  <v-icon class="pr-5" large color="black">mdi-download </v-icon>
                                </v-btn>
                                <v-btn text @click="print">
                                  <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
                                </v-btn>
                            </v-card-title>                                                
                        </v-col>
                       
                        <v-col cols="8" class="d-flex flex-row">
                          <v-spacer></v-spacer>
                          <v-col cols="2">
                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                              transition="scale-transition" offset-y min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateto1" label="From Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                  v-on="on" dense style="padding:0px !important"></v-text-field>
                              </template>
                              <v-date-picker v-model="dateto1" @input="menu = false"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="2">
                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                              transition="scale-transition" offset-y min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateto" label="To Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                  v-on="on" dense style="padding:0px !important"></v-text-field>
                              </template>
                              <v-date-picker v-model="dateto" @input="menu2=false"></v-date-picker>
                            </v-menu>
                          </v-col>
          
                         
          
                          <v-btn text @click="seereport" class="mr-2 font-weight-bold" style="background: gray">
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row> -->

                      <div id="dataapp" class="mt-4">
                        <v-data-table :headers="orderHeaders" :items="all_orders" :loading="loading"
                          :server-items-length="totalItems1" hide-default-footer loading-text="Please wait..."
                          item-key="id" class="elevation-1" style="background: #f5f5f5">
                          <template v-slot:top>
                            <v-card-title>
                              <v-spacer></v-spacer>
                              <!-- <div class="pr-4 pb-4 pt-2 d-flex ">
                                <div class="search g">
                                  <v-text-field v-model="search_key" label="Search by order id, date" filled rounded
                                    dense hide-details>
                                  </v-text-field>
                                </div>
                                <div class="icon-search pr-4 pb-5 pt-2">
                                  <v-btn icon style="background: red"><v-icon @click="searchHandle">mdi-magnify</v-icon>
                                  </v-btn>
                                </div>
                              </div> -->
                            </v-card-title>
                          </template>
                          <!-- <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            <b>Order Refrence: </b> {{ item.id }} <br />
                            <b>User ID : </b> {{ item.user_id }}<br />
                            <b>Date : </b> {{ item.ordered_date }}<br />
                            <b>Host Site : </b> {{ item.host }}<br />
                            <b>Order Status : </b> {{ item.order_status }}<br />
                            <b>Delivery Type : </b> {{ item.delivery_status
                            }}<br />
                            <b>Copun Code : </b> {{ item.coupon_code }}<br />
                            <b>Copun Percentage : </b>
                            {{ item.coupon_percentage }}<br />
                            <b>Total Point : </b> {{ item.point_total }}<br />
                          </td>
                        </template> -->

                          <template v-slot:item.details="{ item }">
                            <v-btn color="primary" style="padding-left: 0px !important" text
                              @click="viewDetails(item)">Details</v-btn>
                          </template>

                          <template v-slot:item.actions="{ item }">
                            <v-btn v-if="item.admin_status == 'Confirmed'" color="primary"
                              style="padding-left: 0px !important" text @click="viewInvoice(item)">Invoice</v-btn>
                          </template>

                          <template v-slot:item.motherinvoice="{ item }">
                            <v-btn @click="viewMotherInvoice(item)" text>
                              <v-icon dark class="pr-5" style="margin-left: 0 !important">
                                mdi-eye-outline</v-icon>
                              {{ item.reference_id }}
                            </v-btn>
                          </template>

                          <template v-slot:item.admin_status="{ item }">
                            <v-chip :color="getColor(item.admin_status)" dark>
                              {{ item.admin_status }}
                            </v-chip>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                    <div class="d-flex justify-content-center mt-5">
                      <!-- <v-col cols="3" md="3"> -->
                      <div class="col-4">
                        Total {{ totalItems1 }} records
                      </div>
                      <!-- </v-col> -->
                      <!-- <v-col cols="3" md="3"> -->
                      <div class="col-4">
                        <v-select dense outlined hide-details v-model="perPage1" label="Items per page"
                          @change="handlePageSizeChange" :items="pageSizes1">
                        </v-select>
                      </div>
                      <!-- </v-col> -->
                      <!-- <v-col cols="6" md="3" > -->
                      <div class="col-4">
                        <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7"
                          @input="handlePageChange">
                        </v-pagination>
                      </div>
                      <!-- </v-col> -->
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    items: ["Pending Orders", "All Orders"],
    expanded: [],
    search: "",
    dateto1: null,
    dateto: null,
    search_key: null,
    singleExpand: false,
    loading: true,
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    order_status: null,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100'],
    orderHeaders: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Invoice ID1", value: "invoice_id", sortable: false },
      { text: "Date", value: "ordered_date", sortable: false },
      { text: "Price ", value: "price_total", sortable: false },
      { text: "Order Status", value: "order_status", sortable: false },
      { text: "Admin Status", value: "admin_status", sortable: false },
      { text: "Delivery Status", value: "delivery_status", sortable: false },
      // { text: "Details", value: "details", sortable: false },
      // { text: "Invoice", value: "actions", sortable: false },

    ],
    all_orders: [],
  }),

  components: {
    RecentOrders: () => import("@/components/base/RecentManagerOrders"),
  },

  methods: {

    handlePageSizeChange(event) {
      this.perPage1 = (event);
      this.loading = true;
      this.initialize();
    },

    handlePageChange(value) {
      this.currentPage1 = value;
      this.loading = true;
      this.initialize();
      window.scrollTo(0, 0);
    },
    initialize() {

      // let perPage ;
      // if (this.perPage1 === 'All'){
      //     perPage = this.totalItems1;
      // }
      // else
      // {
      //     perPage = this.perPage1;
      // }
      //   this.loading = true;
      axios.get(`Cart/inventory_orders/2/1/?page=1&size=10`).then((response) => {

        if (response.status === 200) {
          this.all_orders = response.data.results;
          this.totalItems1 = response.data.count;
          this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
          this.loading = false;
        }
      });
    },





    viewInvoice(item) {
      if (item.reference_id == 0) {
        this.$router.push({
          path: "/supportdashboard/pages/orderinvoice_updated",
          query: {
            id: item.id
          },
        });
      }
      //Create open anew tab 
      else {
        let routeData = this.$router.resolve({
          name: "Invoice Page",
          query: {
            id: item.invoice_id
          },
        });




        // dashboard/pages/edit-product-image?spm=600
        routeData.href = `/supportdashboard/pages/orderinvoice_updated/?id=${item.invoice_id}`;
        window.open(routeData.href, "_blank");

      }
    },
    viewMotherInvoice(item) {
      if (item.reference_id == 0) {
        this.$router.push({
          path: "/supportdashboard/pages/orderinvoice_updated",
          query: { id: item.invoice_id },
        });
      } else {
        console.log("clicked");
        this.$router.push({
          path: "/supportdashboard/pages/motherinvoice_updated",
          query: { id: item.reference_id },
        });
      }
    },
    viewDetails(item) {
      if (item.reference_id == 0) {
        this.$router.push({
          path: "/supportdashboard/pages/confirmproduct",
          query: {
            id: item.id
          },
        });
      }
      //Create open a new tab
      else {
        let routeData = this.$router.resolve({
          name: "Details Page",
          query: {
            id: item.id
          },
        });
        // dashboard/pages/edit-product-image?spm=600
        routeData.href = `/supportdashboard/pages/confirmproduct?id=${item.id}`;
        window.open(routeData.href, "_blank");
      }
    },
    downloadCSV(csv, filename) {
      var csvFile;
      var downloadLink;
      csvFile = new Blob([csv], { type: 'text/csv' });
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },

    exportTableToCSV(filename) {

      var csv = [];
      var rows = document.querySelectorAll(" #dataapp table tr");

      for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++)
          row.push(cols[j].innerText);
        csv.push(row.join(","));
      }

      this.downloadCSV(csv.join("\n"), filename);
    },
    print() {
      var divToPrint = document.getElementById("dataapp");
      // var newWin = window.open("");
      // newWin.document.write(divToPrint.outerHTML);
      // newWin.print();

      //newWin.close();

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;text-align: center;}";
      style = style + "</style>";

      var win = window.open("", "", "height=1900,width=1900");
      win.document.write(style); //  add the style.
      win.document.write(divToPrint.outerHTML);
      win.document.close();
      win.print();
    },
    fromdate() { },
    seereport() {

      this.initialize();
    },
    todate() { },

    getColor(admin_status) {
      if (admin_status == "Cancelled") return "red";
      else return "green";
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>